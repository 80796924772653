export interface Input {
	value: string
}

<legend>
	Time in
	<a href=`https:///en.wikipedia.org/wiki/${input.value}`>${input.value}</a>
</legend>

<time>
	<let/time />
	<effect() {
		time = new Date()
		const interval = setInterval(() => {
			time = new Date()
		}, 1000)
		return () => clearInterval(interval)
	} />
	${time?.toLocaleString([], {
		timeZone: input.value,
		timeStyle: "short",
	})}<noscript>Enable JavaScript to see my time.</noscript>
</time>

<style>
	#clock time {
		font-family: monospace, monospace;
		font-size: x-large;
		line-height: normal;
	}
	/* I think uBlock Origin renames noscript to span for some reason */
	#clock noscript,
	#clock span {
		color: GrayText;
		font-size: medium;
	}
	#clock {
		/* aspect-ratio: 1/1; */
		align-content: center;
		text-align: center;
	}
</style>
